<template>
 <v-card color="natural lighten-4" >
    <v-skeleton-loader
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
 </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>
