<template>
  <v-row class="pl-5 pr-10 pt-5">
    <!-- class="pl-5 pr-10 pt-5" -->
      <v-col cols="12" md="4">
      <div class="text-title-1 natural--text text--darken-4">
          <v-btn icon :to="{name: goback}">
          <v-icon color="natural darken-4">mdi-arrow-left</v-icon>
          </v-btn>
          ย้อนกลับ</div>
      <div class="text-h4 font-weight-bold natural--text text--darken-4 pt-2">{{title}}</div>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="auto" align-self="center">
           <slot name="action"></slot>
      </v-col>
    </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'เนื้อหา Title'
    },
    subtitle: {
      type: String,
      default: 'เนื้อหา Subtitle'
    },
    goback: {
      type: String
    }
  }

}
</script>

<style>

</style>
