<template>
  <v-container fluid class="pl-5 pr-10">
    <SubTitleBar :title="(mode) ? 'แก้ไขแบบฟอร์ม' : 'สร้างแบบฟอร์ม'" :goback="'formlist'">
      <template slot="action">
        สถานะแบบฟอร์ม <Select dense :items="activeItems" item-text="text" item-value="value" v-model="form.active">
           <template v-slot:selection="{ item }">
              <li style="list-style-type: circle;width: -1em;" class="success--text" v-if="item.value"><span class="success--text">พร้อมใช้</span></li>
              <li style="list-style-type: circle;width: -1em;" class="error--text" v-else><span class="error--text">ระงับใช้</span></li>
           </template>
           <template v-slot:item="{ item }">
               <li style="list-style-type: circle;width: -1em;" class="success--text" v-if="item.value"><span class="success--text">พร้อมใช้</span></li>
              <li style="list-style-type: circle;width: -1em;" class="error--text" v-else><span class="error--text">ระงับใช้</span></li>
             </template>
        </Select>
      </template>
    </SubTitleBar>
     <v-row>
      <v-col cols="12">
        <v-card color="natural lighten-4" v-if="!loading">
          <v-card-title class="text-title-1 text--natural text--darken-4">รายละเอียดแบบฟอร์ม</v-card-title>
          <v-card-text>
            <v-row >
              <v-col cols="12" md="3">
               <TextField :title="'ชื่อแบบฟอร์ม'" v-model="form.name" readonly />
              </v-col>
               <v-col cols="12" md="3">
               <Select :title="'ประเภท'" readonly :items="formTypeItems" item-text="formTypeName" item-value="formTypeId" v-model="form.formTypeId"/>
              </v-col>
                <v-col cols="12" md="2" >
               <Select :title="'ประจำปี'" readonly :items="yearItems" v-model="form.year" item-text="yearTH" item-value="yearEN"/>
              </v-col>
              <v-spacer/>
               <!-- <v-col cols="12" md="2" >
               <TextField :title="'ประเภทอื่นๆ'" />
              </v-col> -->
               <v-col cols="12" md="1" >
             <div class="pt-5">
               <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
         <v-btn x-large block color="primary" outlined @click="preview=true" v-bind="attrs" v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
      </template>
      <span>คลิกเพื่อดูตัวอย่าง</span>
    </v-tooltip>
             </div>
              </v-col>
               <v-col cols="12" md="2" >
                <div class="pt-5">
               <v-btn x-large block color="primary" outlined @click="addQuestion()">เพิ่มคำถาม</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
     </v-row>
      <v-row>
        <v-col cols="12">
        <draggable v-model="form.schema.fields">
    <!-- <transition-group> -->
        <v-row dense v-for="(item,index) in form.schema.fields" :key="item.name">
          <v-col cols="12">
        <QuestionBuilderCard v-if="!loading && item.visible" v-model="form.schema.fields[index]" :allquestion="form.schema.fields" @onCopy="copyQuestion(form.schema.fields[index])" @onDelete="deleteConfirm(index)" @onUp="shiftLeft(index)" @onDown="shiftRight(index)" />
          </v-col>
        </v-row>
    <!-- </transition-group> -->
  </draggable>
 </v-col>
      </v-row>
       <v-row class="pb-10">
         <v-spacer/>
         <v-btn v-if="!mode" x-large color="primary" width="248" @click="confirmDialog = true">สร้างแบบฟอร์ม</v-btn>
         <v-btn v-else x-large color="primary" width="248" @click="confirmDialog = true">บันทึก</v-btn>
          <v-spacer/>
       </v-row>
      <v-dialog v-model="preview" fullscreen>
        <PreviewCard @onClose="preview=false"/>
      </v-dialog>
      <v-dialog v-model="confirmDialog" width="384" persitent>
        <ConfirmCard :title="'คุณต้องการบันทึกฟอร์ม ' + form.name + ' เป็นแบบฟอร์มปัจจุบันหรือไม่?'" @onClose="confirmDialog=false" :action="'ยืนยัน'" @onConfirm="(!mode) ? postForm() : createFormDialog=true"/>
      </v-dialog>
        <v-dialog v-model="deleteDialog" width="384" persistent>
        <ConfirmDeleteCard :title="'คุณต้องลบหัวข้อคำถามนี้หรือไม่?'" :subtitle="'หากคุณทำการลบรายการที่คุณทำไว้จะหายไปและจะต้องทำรายการใหม่อีกครั้ง'" :action="'ลบคำถามนี้'" @onClose="deleteDialog=false" @onConfirm="deleteQuestion(currentIndex)" />
        </v-dialog>
         <v-dialog v-model="createFormDialog" width="450" persistent>
      <CreateFormCard @onClose="createFormDialog=false" @onSubmit="postForm" :action="'สร้างฟอร์ม'" :status="createFormDialog" :yearItems="yearItems" :formTypeItems="formTypeItems" v-model="form" />
    </v-dialog>
          <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
     <v-snackbar
        v-model="snackbar"
        color="natural darken-4"
      >
      <v-avatar size="35">
      <v-img width="35" :src="require('@/assets/circlesuccess.svg')"/>
      </v-avatar>
        {{errorMessage}}
        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>
              mdi-close
           </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  </v-container>
</template>

<script>
// import { getSites, getSiteUser } from '@/api/'
import { getYear, getMasterFormType, postForm, patchForm } from '@/api/'
import { mapFields } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'
import SubTitleBar from '@/components/common/SubTitleBar'
import Select from '@/components/common/Select'
import TextField from '@/components/common/TextField'
import draggable from 'vuedraggable'
// import PreviewCard from '@/components/form/PreviewCard'
// import ConfirmDeleteCard from '@/components/common/ConfirmDeleteCard'
import LoadingComponent from '@/components/common/QuestionBuilderSkeleton'
import arrayMove from 'array-move'
// import * as easings from 'vuetify/es5/services/goto/easing-patterns'
// import CreateFormCard from '@/components/form/CreateFormCard'

const QuestionBuilderCard = () => ({
  // The component to load (should be a Promise)
  component: import('@/components/form/QuestionBuilderCard'),
  // A component to use while the async component is loading
  loading: LoadingComponent,
  // A component to use if the load fails
  error: LoadingComponent,
  // Delay before showing the loading component. Default: 200ms.
  delay: 50,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 5000
})

export default {
  props: {
    formId: {
      type: String
    },
    formName: {
      type: String
    },
    formTypeName: {
      type: String
    },
    formTypeId: {
      type: String
    },
    mode: {
      type: Boolean,
      default: false // true (Edit mode) / false (Create mode)
    }
  },
  components: {
    SubTitleBar,
    Select,
    TextField,
    draggable,
    PreviewCard: () => import('@/components/form/PreviewCard'),
    QuestionBuilderCard,
    ConfirmDeleteCard: () => import('@/components/common/ConfirmDeleteCard'),
    ConfirmCard: () => import('@/components/common/ConfirmCard'),
    CreateFormCard: () => import('@/components/form/SaveAsCard')
  },
  data () {
    return {
      loading: true,
      confirmDialog: false,
      deleteDialog: false,
      createFormDialog: false,
      currentIndex: null,
      siteData: {},
      siteUser: [],
      tab: null,
      preview: false,
      yearItems: [],
      formTypeItems: [],
      activeItems: [
        { text: 'พร้อมใช้', value: true, color: 'success' },
        { text: 'ระงับใช้', value: false, color: 'error' }
      ],
      errorMessage: null,
      snackbar: false,
      items: [
        {
          title: 'รหัสสถานบริการ',
          name: 'HOSPCODE',
          required: true,
          type: 'text',
          inputType: 'text',
          visible: false
        },
        {
          title: 'ทะเบียนบุคคล',
          name: 'PID',
          required: true,
          type: 'text',
          inputType: 'text',
          visible: false
        },
        {
          title: 'ลำดับที่',
          name: 'SEQ',
          required: true,
          multiple: false,
          type: 'text',
          inputType: 'text',
          visible: false
        },
        {
          title: 'วันที่ตรวจ',
          name: 'DATE_SERV',
          required: true,
          multiple: false,
          format: 'YYYYMMDD',
          type: 'text',
          inputType: 'text',
          visible: false
        },
        {
          title: 'บริการใน-นอกสถานบริการ',
          name: 'SERVPLACE',
          required: true,
          multiple: false,
          choices: [
            { text: 'ในสถานบริการ', value: '1' },
            { text: 'นอกสถานบริการ', value: '2' }
          ],
          type: 'select',
          visible: false
        },
        {
          title: 'ประวัติสูบบุหรี่',
          name: 'SMOKE',
          required: false,
          multiple: false,
          choices: [
            { text: 'ไม่สูบ', value: '1' },
            { text: 'สูบนานๆครั้ง', value: '2' },
            { text: 'สูบเป็นครั้งคราว', value: '3' },
            { text: 'สูบเป็นประจำ', value: '4' },
            { text: 'ไม่ทราบ', value: '9' }
          ],
          type: 'select',
          visible: true
        },
        {
          title: 'ประวัติดื่มเครื่องดื่มแอลกอฮอลล์',
          name: 'ALCOHOL',
          required: true,
          multiple: false,
          choices: [
            { text: 'ไม่ดื่ม (ในรอบ 1 ปีที่ผ่านมา)', value: '1' },
            { text: 'ดื่มนานๆครั้ง (ดื่ม 1-3 วันต่อเดือน หรือ ดื่ม 1-11 วันต่อปี)', value: '2' },
            { text: 'ดื่มเป็นครั้งคราว (ดื่ม 1-4 วันต่อสัปดาห์)', value: '3' },
            { text: 'ดื่มเป็นประจำ (ดื่ม 5-7 วันต่อสัปดาห์)', value: '4' },
            { text: 'ไม่ทราบ', value: '9' }
          ],
          type: 'select',
          visible: true
        },
        {
          title: 'ประวัติเบาหวานในญาติสายตรง',
          name: 'DMFAMILY',
          required: false,
          multiple: false,
          choices: [
            { text: 'มีประวัติเบาหวานในญาติสายตรง', value: '1' },
            { text: 'ไม่่มี', value: '2' },
            { text: 'ไม่ทราบ', value: '9' }
          ],
          type: 'select',
          visible: true
        },
        {
          title: 'ประวัติความดันสูงในญาติสายตรง',
          name: 'HTFAMILY',
          required: false,
          multiple: false,
          choices: [
            { text: 'มีประวัติความดันโลหิตสูงในญาติสายตรง', value: '1' },
            { text: 'ไม่่มี', value: '2' },
            { text: 'ไม่ทราบ', value: '9' }
          ],
          type: 'select',
          visible: true
        },
        {
          title: 'น้ำหนัก',
          name: 'WEIGHT',
          placeholder: 'กิโลกรัม',
          range: '[20-300]',
          required: true,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'ส่วนสูง',
          name: 'HEIGHT',
          placeholder: 'เซนติเมตร',
          range: '[30-250]',
          required: true,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'เส้นรอบเอว',
          name: 'WAIST_CM',
          placeholder: 'เซนติเมตร',
          range: '[30-250]',
          required: true,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'ความดันโลหิตซิสโตลิก ครั้งที่ 1',
          name: 'SBP_1',
          placeholder: 'มม.ปรอท',
          range: '[30-250]',
          required: true,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'ความดันไดแอสโตลิค ครั้งที่ 1',
          name: 'DBP_1',
          placeholder: 'มม.ปรอท',
          range: '[30-250]',
          required: true,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'ความดันโลหิตซิสโตลิก ครั้งที่ 2',
          name: 'SBP_2',
          placeholder: 'มม.ปรอท',
          range: '[30-250]',
          required: false,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'ความดันไดแอสโตลิค ครั้งที่ 2',
          name: 'DBP_2',
          placeholder: 'มม.ปรอท',
          range: '[30-250]',
          required: false,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'ระดับน้ำตาลในเลือด',
          name: 'BSLEVEL',
          placeholder: 'มก./ดล.',
          range: '[30-250]',
          required: true,
          type: 'text',
          inputType: 'number',
          visible: true
        },
        {
          title: 'วิธีการตรวจน้ำตาลในเลือด',
          name: 'BTEST',
          placeholder: 'มก./ดล.',
          range: '[30-250]',
          required: false,
          type: 'select',
          choices: [
            { text: 'ตรวจน้ำตาลในเลือดจากหลอดเลือดดำ หลังอดอาหาร', value: '1' },
            { text: 'ตรวจน้ำตาบในเลือดจากหลอดเลือดดำ โดยไม่อดอาหาร', value: '2' },
            { text: 'ตรวจน้ำตาลในเลือดจากเส้นเลือดฟอย หลังอดอาหาร', value: '3' },
            { text: 'ตรวจน้ำตาลในเลือดจากเส้นเลือดฟอย โดยไม่อดอาหาร', value: '4' },
            { text: 'ไม่ตรวจน้ำตาลในเลือด เนื่องจากคัดกรอกแบบสอบถามปากเปล่าปกติ', value: '9' }
          ],
          visible: true
        },
        {
          title: 'สถานที่รับบริการคัดกรอง',
          name: 'SCREENPLACE',
          placeholder: '',
          required: false,
          type: 'text',
          inputType: 'text',
          visible: false
        },
        {
          title: 'เลขที่ผู้ให้บริการ',
          name: 'PROVIDER',
          placeholder: '',
          required: true,
          type: 'text',
          inputType: 'text',
          visible: false
        },
        {
          title: 'วันเดือนปีที่ปรับปรุง',
          name: 'U_UPDATE',
          placeholder: '',
          required: true,
          format: 'YYYYMMDDHHmmSS',
          type: 'text',
          inputType: 'text',
          visible: false
        },
        {
          title: 'เลขที่ประจำตัวประชาชน',
          name: 'CID',
          placeholder: '',
          required: true,
          type: 'text',
          inputType: 'text',
          visible: true
        }
      ],
      items2: [
        {
          title: 'เพศ',
          name: 'gender',
          required: true,
          choices: ['ชาย', 'หญิง'],
          multiple: false,
          type: 'select',
          inputType: null
        },
        {
          title: 'น้ำหนัก',
          name: 'weight',
          placeholder: 'กิโลกรัม',
          required: true,
          type: 'text',
          inputType: 'number',
          value: 90
        },
        {
          title: 'ความดันโลหิตตัวบน',
          name: 'sys',
          placeholder: 'mmHg',
          required: true,
          type: 'text',
          inputType: 'number'
        },
        {
          title: 'ความดันโลหิตตัวล่าง',
          name: 'dia',
          placeholder: 'mmHg',
          required: true,
          type: 'text',
          inputType: 'number'
        },
        {
          title: 'อัตราการเต้นหัวใจ',
          name: 'pulse',
          placeholder: 'bpm',
          required: true,
          type: 'text',
          inputType: 'number'
        },
        {
          title: 'วิธีตรวจน้ำตาล',
          name: 'dtx',
          required: true,
          choices: ['DTX', 'FBS'],
          multiple: false,
          type: 'select'
        },
        {
          title: 'การตรวจวัดน้ำตาล',
          name: 'method',
          required: true,
          choices: ['โดยอดอาหาร', 'โดยไม่อดอาหาร'],
          multiple: false,
          type: 'select'
        },
        {
          title: 'ผลตรวจวัดน้ำตาล (mg/dl)',
          name: 'bg',
          placeholder: 'mg/dl',
          required: true,
          type: 'text',
          inputType: 'number'
        },
        {
          title: 'ประวัติการสูบบุหรี่',
          name: 'smoke',
          required: true,
          choices: [
            'ไม่สูบบุหรี่',
            'นานๆครั้ง',
            'เป็นครั้งคราว',
            'สูบเป็นประจำ'
          ],
          multiple: false,
          type: 'select'
        },
        {
          title: 'ประวัติการดื่มแอลกอฮอล',
          name: 'alh',
          required: true,
          multiple: false,
          choices: ['ไม่ดื่ม', 'นานๆครั้ง', 'เป็นครั้งคราว', 'ดื่มเป็นประจำ'],
          type: 'select'
        },
        {
          title: 'ญาติสายตรงของท่านมีประวัติการเจ็บป่วย',
          name: 'familyDesease',
          required: true,
          choices: ['ไม่มีโรค', 'มีโรค', 'ไม่ทราบ'],
          type: 'select',
          multiple: false,
          value: 'ไม่มีโรค'
        },
        {
          title: 'โรคประจำตัวของญาติท่าน',
          name: 'familyNCD',
          required: true,
          visibleIf: "{familyDesease}='มีโรค'",
          choices: ['เบาหวาน', 'ความดัน'],
          multiple: true,
          type: 'select'
        }
      ]
    }
  },
  methods: {
    addQuestion () {
      this.form.schema.fields.push({ name: 'name' + this.form.schema.fields.length, choices: [], visible: true })
      this.$vuetify.goTo(9999, { duration: 300, offset: 0, easing: 'easeInOutCubic' })
    },
    copyQuestion (item) {
      const copyItem = cloneDeep(item)
      copyItem.name = 'copy' + this.form.schema.fields.length
      this.form.schema.fields.push(copyItem)
    },
    deleteConfirm (index) {
      this.currentIndex = index
      this.deleteDialog = true
    },
    shiftLeft (index) {
      console.log(index)
      arrayMove.mutate(this.form.schema.fields, index, index - 1)
    },
    shiftRight (index) {
      console.log(index)
      arrayMove.mutate(this.form.schema.fields, index, index + 1)
    },
    deleteQuestion (index) {
      this.form.schema.fields.splice(index, 1)
      this.deleteDialog = false
    },
    postForm () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      const data = { ...this.form, tenantId: tenant.tenantId, siteId: site.siteId }
      // console.log(data)
      postForm(data, message => {
        if (message.data.code === 1) {
          if (this.mode) {
            this.postStatus = { code: true, message: 'แก้ไขแบบฟอร์มสำรวจสำเร็จ !' }
          } else {
            this.postStatus = { code: true, message: 'สร้างแบบฟอร์มสำรวจสำเร็จ !' }
          }
          this.$router.push({ name: 'formlist' })
        }
        this.confirmDialog = false
      }, error => {
        this.confirmDialog = false
        if (error.response.data.code === 400) {
          this.errorMessage = error.response.data.message
          this.snackbar = true
        }
      })
    },
    async fetchData () {
      if (this.$auth.role) {
        console.log('form')
        console.log(this.form)
        this.loading = true
        try {
          let resp = await getYear()
          if (resp.data.code === 1) {
            this.yearItems = resp.data.result
            this.year = [String(new Date().getFullYear())]
          }
          resp = await getMasterFormType({ keyword: '' })
          if (resp.data.code === 1) {
            console.log(resp.data.result)
            this.formTypeItems = resp.data.result
            this.formTypeItems.push({ formTypeId: '', formTypeName: 'ทั้งหมด' })
            this.formType = ''
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    ...mapFields(['form', 'postStatus'])
  },
  watch: {
    'form.active': function (val) {
      const data = { active: val }
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      patchForm({ id: this.form.id, etag: this.form._etag, tenantId: tenant.tenantId, siteId: site.siteId }, data, message => {
        console.log(message)
      }, error => {
        console.log(error)
      })
    }
  },
  mounted () {
    console.log('mounted')
  },
  updated () {
    console.log('update')
  },
  beforeUpdate () {
    console.log('beforeupdate')
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        console.log('create')
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
